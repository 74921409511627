import { FORMPOST } from "../../../API/post";
import { jobsRef } from "../../../firebase";
import { getUserInfo } from "../../../storage";

// Get user info
const user_info = getUserInfo();

export const loadMessages = async (job_firebase_document_id, dispatch) => {
    if(job_firebase_document_id){
        dispatch({type: "IS_LOADING"});

        let query = jobsRef.doc(job_firebase_document_id).collection('job_messages').orderBy('message_time');

        query.onSnapshot(async (snapshot) => {
            dispatch({
                type: "IS_LOADED",
                messages_list: snapshot.docs.map(doc => doc.data()),
            });
        });
    }
}

export const sendTextMessages = async (data, job_firebase_document_id, reset) => {
    // Add a new message to the messages collection
    await jobsRef.doc(job_firebase_document_id).collection('job_messages').add({
        message_content: {
            text: data.text,
            files: []
        },
        message_sender: {
            user_doc_id: user_info.customer_firebase_document_id,
            user_id: user_info.customer_id,
            user_name: user_info.customer_name,
            user_type: "CUSTOMER"
        },
        message_time: new Date().getTime()
    });

    // Reset the state or props as needed
    reset();
}

export const filePreView = (images, dispatch) => {
    let content = [];
    let count = 0;

    if(typeof images !== 'undefined'){
        for (let i = 0; i < images.length; i++) {
            content.push(
                <div className="img-wraps" key={count++}>
                    <span className="closes" title="Delete" onClick={() => removeImage(i, images, dispatch)}>×</span>
                    <img 
                        src={URL.createObjectURL(images[i])} 
                        style={{
                            height: "70px",
                            width: "70px"
                        }}
                        alt="Avatar"
                        className="img-responsive"
                    />
                </div>
            );
        }
    }

    return count > 0 ? content : <p><b>YOUR IMAGES</b></p>;
}

export const removeImage = (index, images, dispatch) => {
    images.splice(index, 1);
    
    dispatch({
        type: "SET_IMAGES",
        images: images
    });

    filePreView(images);
}

export const loadImage = (images, files, dispatch) => {
    let files_list = typeof images !== 'undefined' ? images : [];

    for(let file of files){
        files_list.push(file);
    }

    dispatch({
        type: "SET_IMAGES",
        images: files_list
    });
}

export const sendMessageWithImages = async ({data, job_firebase_document_id, images, dispatch, reset, setIsOpen}) => {
    dispatch({type: "IS_LOADING_BTN", is_loading_btn: true});

    let upload_images = [];
    if(images.length > 0){
        let response = await FORMPOST("massages/files/upload", {
            images: images
        });

        if(response.status === "Success"){
            dispatch({type: "IS_LOADING_BTN", is_loading_btn: false});
            for(let item of response.list){
                upload_images.push({
                    path: item.image_path
                });
            }
        }
    }

    // Add a new message to the messages collection
    await jobsRef.doc(job_firebase_document_id).collection('job_messages').add({
        message_content: {
            text: data.caption,
            files: upload_images
        },
        message_sender: {
            user_doc_id: user_info.customer_firebase_document_id,
            user_id: user_info.customer_id,
            user_name: user_info.customer_name,
            user_type: "CUSTOMER"
        },
        message_time: new Date().getTime()
    });

    reset();
    dispatch({
        type: "SET_IMAGES",
        images: []
    });
    setIsOpen(false);
}