import React, { useContext } from 'react';
import Badge from 'react-bootstrap/Badge';
import { Card, CardContent, Typography, Box } from '@mui/material';

import Context from "../context";
import AlertDirection from './alertDirection';
import AlertItems from './alertItem';
import AlertBreakdown from './alertBreakdown';

const AlertBody = () => {
    const context = useContext(Context);

    return (
        <>
            <p>Please, can we have your authorization?</p>
            <p>Additional costs details are as follows</p>
            <AlertDirection />
            <Box sx={{ pb: 2 }}>
                <Badge bg="success" sx={{ pb: 2 }}>REQUESTED EXTRA MATERIALS</Badge>
            </Box>

            {context?.state?.request_full_data?.materials.map((item, index) => (
                <React.Fragment key={index}>
                    <Card>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                {item.request_material_item}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Description
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {item.request_material_description}
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                Cost
                            </Typography>
                            <AlertItems item={item} />
                        </CardContent>
                    </Card>
                    <hr />
                </React.Fragment>
            ))}

            <hr />
            <AlertBreakdown />
        </>
    );
}

export default React.memo(AlertBody);