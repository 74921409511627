import React from "react";
import Backdrop from '@mui/material/Backdrop';

const Loader = () => {
    return(
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
        >
            <img 
                src={`/assets/img/instafix-logo-1.png`} 
                className="navbar-brand-img" 
                alt="main_logo"
                style={{
                    width: '20%',
                    height: '10%'
                }}  
            />
        </Backdrop>
    );
}
  
export default Loader;