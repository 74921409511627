import React, {useContext} from "react";
import Button from 'react-bootstrap/Button';
import Tooltip from '@mui/material/Tooltip';

import Context from "../context";
import { acceptOrRejectMoreTimeAndMaterials } from '../service';
import BtnSpinner from "../../../../common/btnSpinner";

const ActionButtons = () => {
    const context = useContext(Context);

    const buttons = [
        {
            color: context.theme.action_btn,
            onClickFunction: () => acceptOrRejectMoreTimeAndMaterials({
                request_data: context.state.request_data, 
                dispatch: context.dispatch,
                is_approve: 1,
                listener: context.listener,
            }),
            btn_text: "APPROVE",
            tooltip_text: "Approve this request"
        },
        {
            color: context.theme.reject_btn,
            onClickFunction: () => acceptOrRejectMoreTimeAndMaterials({
                request_data: context.state.request_data, 
                dispatch: context.dispatch,
                is_approve: -1,
                listener: context.listener,
            }),
            btn_text: "REJECT",
            tooltip_text: "Reject this request",
        },
        {
            color: context.theme.quote_for_later_date_btn,
            onClickFunction: () => context.dispatch({
                type: "IS_JOB_SHOW_FORWARDING", 
                is_job_show_forwarding: true
            }),
            btn_text: "LATER DATE",
            tooltip_text: "Quote for later date",
        }
    ];

    return(
        <>
            <div className="container">
                <div className="row" style={{marginTop: '10px'}}>
                    <div className="col">
                        <div className="text-center mb-3"> 
                            <div className="row text-center">
                                {buttons.map((item, index) => (
                                    <div className="col-4" key={index}>
                                        <div className="d-grid gap-2">
                                            <Tooltip title={item.tooltip_text} placement="top" arrow>
                                                <Button 
                                                    variant={item.color}
                                                    size="sm"
                                                    onClick={() => item.onClickFunction()}
                                                    disabled={context?.state?.is_loading}
                                                >
                                                    {context?.state?.is_loading ? (
                                                        <BtnSpinner/>
                                                    ) : item.btn_text}
                                                </Button>  
                                            </Tooltip>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ActionButtons;