export const initialState = {
    is_loading: false,
    request_data: {},
    request_full_data: {},
    job_is_have_remedial_job :false,
    show: false,
    is_show_request_details: false,
    request_status: "",
    is_job_show_forwarding: false,
    is_show_time_count_down_timer: false,
}