import React from "react";
import ImageGallery from "./imageGallery";
import { theme } from "../../../../theme";
import { formatHHMM } from "../../../../utils";

const Right = ({message, ref}) => {
    return (
        <div className="msg right-msg" ref={ref}>
            <div 
                className="msg-bubble"
                style={{
                    backgroundColor: theme.right_msg_bg_color,
                    color: theme.right_msg_text_color
                }}
            >
                <div className="msg-info">
                    <div className="msg-info-name">{message.message_sender?.user_name} ({message.message_sender?.user_type})</div>
                    <div className="msg-info-time">{formatHHMM(message.message_time)}</div>
                </div>

                <div className="msg-text">
                    {message.message_content?.text}
                    {message.message_content?.files?.length > 0 ? <ImageGallery images = {message.message_content?.files}/> : null}
                </div>
            </div>
        </div>
    );
}

export default Right;