import React, { Suspense, useEffect, useReducer, useCallback } from "react";
import { Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast';

import "./App.css";

import DirectChat from "../jobs/components/chat";
import DisputesChat from "../jobs/disputes/chat";
import OfficeChat from "../customer/chat";

import Context from "../listener/context";
import { onMessageListener } from "../firebase";
import SnackbarAlert from "../common/snackbar";
import { checkTermsAndConditions, isOpenNotification, jobFirebaseListener, requestNotificationPermission, showSnackbar, userFirebaseListener } from "./service";

import FtmRequestAlert from "../jobs/ftmRequest/alert/alert";
import RemedialJobAlert from "../jobs/remedialJob/alert/alert";
import RescheduleJobAlert from "../jobs/rescheduleJob/alert";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import { getCurrentJob, setAppOpen, getUserInfo, removeAppOpen, getAppOpen } from "../storage";
import useCurrentLocation from "../common/hooks/useCurrentLocation";
import { routes } from "./routes";
import Loader from "../common/loading";
import useCustomNavigate from "../common/hooks/useCustomNavigate";

function App() {
    const [state, dispatch] = useReducer(Reducer, initialState);
    const { pathname } = useCurrentLocation();
    const { navigateToCustom } = useCustomNavigate();
    const user_info = getUserInfo();

    const handleStorageChange = useCallback((event) => {
        if (event.key === 'app_open' && event.newValue === null) {
            setAppOpen();
        }
    }, []);

    useEffect(() => {
        const checkTab = () => {
            if (getAppOpen()) {
                alert(`This ${process.env.REACT_APP_SYSTEM_NAME} is already open in another tab. Please use only one tab at a time.`);
                window.close();
            } else {
                setAppOpen();
            }
        };

        checkTab();
        window.addEventListener('storage', handleStorageChange);
        window.addEventListener('beforeunload', removeAppOpen);

        return () => {
            removeAppOpen();
            window.removeEventListener('storage', handleStorageChange);
            window.removeEventListener('beforeunload', removeAppOpen);
        };
    }, [handleStorageChange]);

    useEffect(() => {
        requestNotificationPermission();
    }, []);

    useEffect(() => {
        const termsAndConditions = async () => {
            const response = await checkTermsAndConditions();

            if (response.is_approved_terms_and_conditions === 0) {
                navigateToCustom("/sign/up/terms");
            }
        };

        if (pathname !== "/" && pathname !== "/sign/in" && !pathname.startsWith("/sign/up")) {
            !user_info?.customer_is_approved_terms_and_conditions && termsAndConditions();
        }
    }, [pathname]);

    useEffect(() => {
        const current_job = getCurrentJob();

        if (current_job) {
            jobFirebaseListener(current_job, dispatch, pathname);
        }

        if (user_info) {
            userFirebaseListener(dispatch, pathname);
        }
    }, []);

    onMessageListener().then((payload) => {
        console.log("useEffect onMessageListener : ", payload);
        showSnackbar(payload, dispatch);
        dispatch({ type: 'SET_PAYLOAD', payload });
        isOpenNotification(payload, dispatch);
    }).catch((err) => console.log('Failed: ', err));

    return (
        <Context.Provider value={{ ...state, dispatch }}>
            <Suspense fallback={<Loader />}>
                <Routes>
                    {routes.map((route, index) => (
                        <Route key={index} path={route.path} element={route.element} />
                    ))}
                </Routes>
            </Suspense>

            <Toaster position="top-right" reverseOrder={false} />

            <SnackbarAlert
                open={state.snackbar?.is_show}
                handleClose={() => dispatch({ type: 'SET_SNACKBAR', payload: {} })}
                type={state.snackbar?.type}
                msg={state.snackbar?.msg}
            />

            <DirectChat
                show={state.directChat?.is_show}
                job_id={state.directChat?.job_id}
                job_firebase_document_id={state.directChat?.job_firebase_document_id}
                handleClose={() => dispatch({ type: 'SET_DIRECTCHAT', directChat: {} })}
            />

            <DisputesChat
                show={state.disputesChat?.is_show}
                dispute={state.disputesChat?.dispute}
                handleClose={() => dispatch({ type: 'SET_DISPUTESCHAT', disputesChat: {} })}
            />

            <OfficeChat
                show={state.officeChat?.is_show}
                handleClose={() => dispatch({ type: 'SET_OFFICECHAT', officeChat: {} })}
            />

            <FtmRequestAlert
                show={state.ftmRequestAlert?.is_show}
                request_data={state.ftmRequestAlert?.request_data}
                handleClose={() => dispatch({ type: 'SET_FTMREQUESTALERT', ftmRequestAlert: {} })}
            />

            <RemedialJobAlert
                show={state.remedialJobAlert?.is_show}
                job_id={state.remedialJobAlert?.job_id}
                handleClose={() => dispatch({ type: 'SET_REMEDIALJOBALERT', remedialJobAlert: {} })}
            />

            <RescheduleJobAlert
                show={state.rescheduleJobAlert?.is_show}
                reschedule_job_id={state.rescheduleJobAlert?.reschedule_job_id}
                handleClose={() => dispatch({ type: 'SET_RESCHEDULEJOBALERT', rescheduleJobAlert: {} })}
            />
        </Context.Provider>
    );
}

export default App;