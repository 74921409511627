import { disputesRef, jobsRef, usersRef } from "../firebase";
import { getUserInfo } from "../storage";
import { getFirebaseDisputes, getFirebaseJob } from "../utils";
import { PUT } from '../API/put';
import { GET } from '../API/get';
import { showSwalAlert } from '../common/swalAlert';
import { theme } from "../theme";

const user_info = getUserInfo();

export const isOpenNotification = async (payload, dispatch) => {
    const { title, body } = payload?.data || {};
    const parsedBody = JSON.parse(body);

    switch (title) {
        case "NEW_DIRECT_MESSAGE":
            openJobChatView(parsedBody, dispatch);
            break;
        case "NEW_DISPUTE_MESSAGE":
            openDisputChatView(parsedBody, dispatch);
            break;
        case "NEW_OFFICE_MESSAGE":
            openOfficeChatView(dispatch);
            break;
        case "FTM_REQUEST_MORE_TIME_AND_MATERIALS":
            openFtmRequestAlertView(parsedBody, dispatch);
            break;
        case "CUS_NEW_REMEDIAL_JOB":
            openRemedialJobAlertView(parsedBody.job_id, dispatch);
            break;
        case "RESCHEDULE_JOB_REQUEST":
            openRescheduleJobAlertView(parsedBody, dispatch);
            break;
        default:
            break;
    }
};

const updateFirebaseDocument = async (ref, user_info) => {
    await ref.update({
        [`${ref.path.split('/')[0]}_last_message_seen_user`]: user_info.customer_firebase_document_id,
        [`${ref.path.split('/')[0]}_last_message_seen_timestamp`]: new Date().getTime(),
    });
};

export const openJobChatView = async (job_id, dispatch) => {
    try {
        isShowChatNotifications(job_id, "NEW_DIRECT_MESSAGE");
        const job_details = await getFirebaseJob(job_id);
        
        const ref = jobsRef.doc(job_details.doc_id);
        
        await updateFirebaseDocument(ref, user_info);

        dispatch({
            type: 'SET_DIRECTCHAT',
            directChat: {
                is_show: true,
                job_id,
                job_firebase_document_id: job_details.doc_id
            }
        });
    } catch (error) {
        console.error("Error opening chat document:", error);
        showSwalAlert({
            type: "error", 
            alertObj: {
                html: `<b>You enter invalid job id</b>`,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: theme.main_code,
                cancelButtonText: 'OK',
                allowOutsideClick: false,
            }, 
            thenFunction: () => {}
        })
    }
};

export const openDisputChatView = async (job_id, dispatch) => {
    try {
        isShowChatNotifications(job_id, "NEW_DISPUTE_MESSAGE");
        const dispute = await getFirebaseDisputes(job_id);

        if (Object.keys(dispute).length > 0) {
            const ref = disputesRef.doc(dispute.doc_id);
            
            await updateFirebaseDocument(ref, user_info);

            dispatch({
                type: 'SET_DISPUTESCHAT',
                disputesChat: {
                    is_show: true,
                    dispute
                }
            });
        } else {
            showSwalAlert({
                type: "error",
                alertObj: {
                    html: "<b>No dispute messages available.</b>",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonColor: theme.main_code,
                    cancelButtonText: 'OK',
                    allowOutsideClick: false,
                },
                thenFunction: () => {}
            });
        }
    } catch (error) {
        console.error("Error opening chat document:", error);
        showSwalAlert({
            type: "error", 
            alertObj: {
                html: `<b>You enter invalid job id</b>`,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonColor: theme.main_code,
                cancelButtonText: 'OK',
                allowOutsideClick: false,
            }, 
            thenFunction: () => {}
        })
    }
};

export const openOfficeChatView = async (dispatch) => {
    try {
        isShowChatNotifications(0, "NEW_OFFICE_MESSAGE");
        const ref = usersRef.doc(user_info.customer_firebase_document_id);
        
        await updateFirebaseDocument(ref, user_info);

        dispatch({
            type: 'SET_OFFICECHAT',
            officeChat: { is_show: true }
        });
    } catch (error) {
        console.error("Error opening office chat:", error);
    }
};

export const openFtmRequestAlertView = (request, dispatch) => {
    dispatch({
        type: 'SET_SNACKBAR_AND_FTMREQUESTALERT',
        snackbar: {
            is_show: true,
            type: 'alert',
            msg: "You have a new FTM request."
        },
        ftmRequestAlert: {
            is_show: true,
            request_data: {
                job_id: request.job_id,
                request_id: parseInt(request.request_id)
            }
        }
    });
};

export const showSnackbar = (payload, dispatch) => {
    if (payload?.data?.message) {
        const msg = JSON.parse(payload.data.message);
        dispatch({
            type: 'SET_SNACKBAR',
            payload: {
                is_show: true,
                type: 'alert',
                msg: msg?.body
            }
        });
    }
};

export const requestNotificationPermission = async () => {
    const permission = await Notification.requestPermission();
    const statusMessage = {
        granted: "Notification permission granted",
        denied: "Notification permission denied",
        default: "Notification permission dismissed"
    };

    if (permission === "denied") {
        showSwalAlert({
            type: "error",
            alertObj: {
                html: "<b>Notification permission denied</b>",
                showConfirmButton: false,
                allowOutsideClick: false
            },
            thenFunction: () => {}
        });
    }

    console.log(statusMessage[permission] || statusMessage.default);
};

export const openRemedialJobAlertView = (job_id, dispatch) => {
    dispatch({
        type: 'SET_SNACKBAR_AND_REMEDIALJOBALERT',
        snackbar: {
            is_show: true,
            type: 'alert',
            msg: "You have a new remedial job."
        },
        remedialJobAlert: {
            is_show: true,
            job_id
        }
    });
};

export const openRescheduleJobAlertView = (reschedule_job_id, dispatch) => {
    dispatch({
        type: 'SET_SNACKBAR_AND_RESCHEDULEJOBALERT',
        snackbar: {
            is_show: true,
            type: 'alert',
            msg: "You have a new reschedule job."
        },
        rescheduleJobAlert: {
            is_show: true,
            reschedule_job_id
        }
    });
};

/*************************************** START FIREBASE LISTENER ****************************************/
export const jobFirebaseListener = async (newValue, dispatch, pathname) => {
    if (newValue?.job_firebase_document_id) {
        jobsRef.doc(newValue.job_firebase_document_id).onSnapshot(async (doc) => {
            const details = doc.data();
            console.log("jobFirebaseListener : ", details);
            if (details?.job_ftm_new_request && !["/", "/lockscreen", "/sign/in", "/sign/up/registration"].includes(pathname)) {
                openFtmRequestAlertView({
                    job_id: details.job_id, 
                    request_id: details.job_ftm_new_request
                }, dispatch);
            }
        });
    }
};

export const userFirebaseListener = async (dispatch, pathname) => {
    if (user_info?.customer_firebase_document_id && !["/", "/lockscreen", "/sign/in", "/sign/up/registration"].includes(pathname)) {
        usersRef.doc(user_info.customer_firebase_document_id).onSnapshot((doc) => {
            const details = doc.data();
            if (details?.user_new_remedial_job) {
                openRemedialJobAlertView(details.user_new_remedial_job, dispatch);
            }
            if (details?.user_new_reschedule_job) {
                openRescheduleJobAlertView(details.user_new_reschedule_job, dispatch);
            }
        });
    }
};
/*************************************** END FIREBASE LISTENER ****************************************/

export const isShowChatNotifications = (job_id, message_type) => {
    PUT("customer/show/notification", {
        customer_id: user_info.customer_id,
        job_id,
        message_type
    });
};

export const checkTermsAndConditions = async () => {
    const response = await GET(`customer/check/is/approved/terms/and/condition/${user_info.customer_id}`);
    return response.status === "Success" ? response : null;
};