import { POST } from "../../../API/post";

export const getTimeString = (today, value) => {
    if (value == 1) {
        let newTime = new Date(today.getTime());
        return String(newTime.getHours()).padStart(2, '0') + ":" + String(newTime.getMinutes()).padStart(2, '0');
    } else {
        let newTime = new Date(today.getTime() + (value * 5 * 60000));
        return String(newTime.getHours()).padStart(2, '0') + ":" + String(newTime.getMinutes()).padStart(2, '0');
    }
}

export const setupRange = (state, dispatch) => {
    let start_time_srt = getTimeString(state.today, 1);
    let end_time_srt = getTimeString(state.today, 10);

    let start_time_timestamp = getFullTimestamp(state.today, 1);
    let end_time_timestamp = getFullTimestamp(state.today, 10);

    dispatch({
        type: "SET_JOB_FORWARDING_TIME_DETAILS",
        job_forwarding_time_details: {
            ...state.job_forwarding_time_details,
            curTime: start_time_srt,
            start_time_timestamp: start_time_timestamp,
            eta: end_time_srt,
            end_time_timestamp: end_time_timestamp,
            isInvalidTime: false
        }
    });
}

export const handleRangeChange = (event, newValue, activeThumb, context) => {
    if (!Array.isArray(newValue)) {
        return;
    }

    let value_0;
    let value_1;

    if (activeThumb === 0) {
        value_0 = Math.min(newValue[0], context.state.range_value[1] - context.state.min_distance);
        value_1 = context.state.range_value[1];
    } else {
        value_0 = context.state.range_value[0];
        value_1 = Math.max(newValue[1], context.state.range_value[0] + context.state.min_distance);
    }

    if(value_0 === 1){
        value_1--;
    }

    context.dispatch({
        type: 'SET_RANGE_VALUE',
        range_value: [value_0, value_1]
    });

    let start_time_srt = getTimeString(context.state.today, value_0);
    let end_time_srt = getTimeString(context.state.today, value_1);

    let start_time_timestamp = getFullTimestamp(context.state.today, value_0);
    let end_time_timestamp = getFullTimestamp(context.state.today, value_1);

    let time_window;
    if(value_0 === 1){
        time_window = value_1/12;
    }else{
        time_window = (value_1 - value_0)/12;
    }

    context.dispatch({
        type: "SET_JOB_FORWARDING_TIME_DETAILS",
        job_forwarding_time_details: {
            ...context.state.job_forwarding_time_details,
            timeWindow: time_window,
            curTime: start_time_srt,
            start_time_timestamp: start_time_timestamp,
            eta: end_time_srt,
            end_time_timestamp: end_time_timestamp,
            isInvalidTime: false
        }
    });
}

export const getFullTimestamp = (today,value) => {
    if(value === 1){
      return today.getTime();
    }else{
      return today.getTime() + (value * 5 * 60000);
    }
}

export const sendJobForwarding = async (context) => {
    context.dispatch({
        type: 'IS_SENDING_REQUEST',
        is_sending_request: true
    });

    let response = await POST("job/forwarding", {
        job_id: context.request_data.job_id,
        request_id: context.request_data.request_id,
        forwarding_start_timestamp: context.state.job_forwarding_time_details?.start_time_timestamp,
        forwarding_end_timestamp: context.state.job_forwarding_time_details?.end_time_timestamp,
    });

    context.dispatch({
        type: 'IS_SENDING_REQUEST',
        is_sending_request: false
    });
    
    if(response.status === "Success"){
        context.closeFtmRequestAlert();
        context.closeModal();
    }
}