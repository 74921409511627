export default function Reducer(prevState, action){
    switch (action.type) {
        case "LOADING":
            return {
                ...prevState,
                is_loading: true
            };
        case "LOADED":
            return {
                ...prevState,
                is_loading: false
            };
        case "SET_JOB_FORWARDING_TIME_DETAILS":
            return {
                ...prevState,
                job_forwarding_time_details: action.job_forwarding_time_details
            };
        case "SET_RANGE_VALUE":
            return {
                ...prevState,
                range_value: action.range_value
            };
        case "IS_SENDING_REQUEST":
            return {
                ...prevState,
                is_sending_request: action.is_sending_request
            };
        default:
            throw new Error();
    }
}