import React from "react";
import Modal from 'react-bootstrap/Modal';

const ModalTemplateWithForm = ({
    show,
    closeFun,
    size,
    centered,
    title,
    body,
    footer,
    onSubmit,
    fullscreen,
    dialogClassName
}) => {
    return (
        <Modal 
            show={show} 
            onHide={closeFun}
            aria-labelledby="contained-modal-title-vcenter"
            centered = {centered}
            size={null}
            fullscreen={fullscreen}
            dialogClassName = {dialogClassName}
        >
            <form onSubmit={onSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {body}
                </Modal.Body>
                <Modal.Footer>
                    {footer}
                </Modal.Footer>  
            </form> 
        </Modal>
    );
}

export default ModalTemplateWithForm;