import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import Context from "../context";
import { theme } from '../../../../theme';
import { moneyFormat } from '../../../../utils';

export default function AlertBreakdown() {
    const context = useContext(Context);

    return (
        <>
            <TableContainer component={Paper} sx={{pb: 2}}>
                <Box sx={{ pt: 2, pl: 2 }}>
                    <Typography gutterBottom variant="h6" component="div">
                        Cost Breakdown
                    </Typography>
                </Box>
                <Divider />
                <Table aria-label="spanning table" size='small'>
                    <TableBody sx={{mb: 2}}>
                        <TableRow>
                            <TableCell colSpan={2} sx={{ border: 0 }}>Total Material Cost</TableCell>
                            <TableCell align="right" sx={{ border: 0 }}>{theme.currency} {moneyFormat(context?.state?.request_full_data?.total_material_price_with_commission)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} sx={{ border: 0 }}>Total VAT</TableCell>
                            <TableCell align="right" sx={{ border: 0 }}>{theme.currency} {moneyFormat(context?.state?.request_full_data?.total_vat)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} sx={{ borderTop: 0 }}><b>Total Gross</b></TableCell>
                            <TableCell align="right" sx={{ borderTop: 0 }}><b>{theme.currency} {moneyFormat(context?.state?.request_full_data?.total_gross)}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}