import React, { useContext, useEffect, useRef, useReducer } from "react";
import Snackbar from '@mui/material/Snackbar';
import { Typography } from '@mui/material';
import ListenerContext from "../../../listener/context";
import { autoRejectMoreTimeAndMaterials, openFtmRequestAlertNotification } from "./service";
import Reducer from "./reducer";
import { initialState } from "./initialState";
import Context from "./context";
import { theme } from '../../../theme';
import ViewBtn from "./components/viewBtn";
import AlertBody from "./components/alertBody";
import ActionButtons from "./components/actionBut";
import CloseBtn from "./components/closeBut";
import JobForwardingModal from "../../jobForwarding/modal";
import CountdownTimer from "../../../common/countdownTimer";
import OffcanvasTemplate from "../../../common/offcanvasTemplate";
import ApproveView from "./components/approveView";
import RejectView from "./components/rejectView";

const FtmRequestAlert = ({ show, request_data, handleClose }) => {
    const listener = useContext(ListenerContext);
    const [state, dispatch] = useReducer(Reducer, initialState);
    const audioPlayer = useRef(null);

    useEffect(() => {
        openFtmRequestAlertNotification(request_data, dispatch)
    }, [listener.payload, request_data]);

    return (
        <>
            <Context.Provider value={{ request_data, theme, state, dispatch, listener }}>
                <OffcanvasTemplate
                    open={show}
                    handleClose={() => {
                        dispatch({ type: "CLOSE_TIME_COUNT_DOWN" });
                        handleClose();
                    }}
                    placement={'end'}
                    title={state?.is_show_request_details && state?.request_status !== "APPROVE" && state?.request_status !== "REJECT" && (
                        <Typography variant="h6" className="mb-3">
                            Your tradeperson is on site and requesting additional costs in order to proceed.
                        </Typography>
                    )}
                    body={state?.is_show_request_details ? (
                        <div className="container mt-1">
                            <div className="row">
                                {state?.request_status === "APPROVE" && <ApproveView />}
                                {state?.request_status === "REJECT" && <RejectView />}
                            </div>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <AlertBody />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="ftm-request-notification-body">
                            <h3>REQUEST</h3>
                            <img src="/assets/gif/bell.gif" className="ftm-request-notification-body-icon" alt="bellIcon" />
                            <p className="r3 px-md-5 px-sm-1">Please find the requested extra time or materials by Tradeperson</p>
                        </div>
                    )}
                    footer={
                        <div className="offcanvas-footer">
                            {state?.is_show_request_details ? (
                                <>
                                    {state?.request_status !== "APPROVE" && state?.request_status !== "REJECT" ? (
                                        <ActionButtons />
                                    ) : (
                                        <CloseBtn handleClose={() => handleClose()} />
                                    )}
                                </>
                            ) : (
                                <ViewBtn />
                            )}
                        </div>
                    }
                    style={null}
                    backdrop={false}
                />
                <audio ref={audioPlayer} src="/assets/mp3/ringtone.mp3" />
                <Snackbar
                    open={state.is_show_time_count_down_timer}
                    message={
                        <>
                            You have 10 min to accept this request before it expires! &nbsp;
                            <CountdownTimer
                                timer={theme.ftm_request_more_time_and_materials_auto_timeout}
                                onComplete={() => autoRejectMoreTimeAndMaterials({ request_data: state.request_data, dispatch: dispatch, is_approve: -1, listener: listener })}
                            />
                        </>
                    }
                />
                <JobForwardingModal
                    show={state.is_job_show_forwarding}
                    request_data={state.request_data}
                    job_is_have_remedial_job={state.job_is_have_remedial_job}
                    closeFtmRequestAlert={() => {
                        dispatch({ type: "CLOSE_TIME_COUNT_DOWN" });
                        handleClose();
                    }}
                    closeModal={() => dispatch({ type: "IS_JOB_SHOW_FORWARDING", is_job_show_forwarding: false })}
                />
            </Context.Provider>
        </>
    );
}

export default FtmRequestAlert;