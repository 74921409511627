import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export default function DatePickerCalendar({label_text}) {
    const tomorrow = dayjs().add(1, 'day');

    return (
        <div id="customDatePickerCalendar">
            <label>{label_text}</label>
            <LocalizationProvider dateAdapter={AdapterDayjs} style={{width: '100%'}}>
                <DemoContainer components={['DatePicker']} >
                    <DatePicker defaultValue={tomorrow} disablePast slotProps={{ textField: { size: 'small' } }} fullWidth={true} />
                </DemoContainer>
            </LocalizationProvider>
        </div>
    );
}