import React, { useContext } from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Context from "../context";
import { theme } from '../../../../theme';
import { truncateText } from '../../../../utils';

export default function Job() {
    const context = useContext(Context);

    return (
        <Card variant="outlined">
            <Box sx={{ p: 2 }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography gutterBottom variant="h5" component="div">
                        {truncateText(context.state?.job_details?.job_skill?.sub_skill?.sub_skill_name, 20)}
                    </Typography>
                    <Typography gutterBottom variant="h6" component="div">
                        {theme.currency} {context.state?.job_details?.job_ftm_hourly_rate}
                    </Typography>
                </Stack>
                <Typography color="text.secondary" variant="body2">
                    {context.state?.job_details?.job_description}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
                <div className="scroll-container">
                    {context?.state?.job_details?.job_images?.map((item, index) => (
                        <img src={item.job_image_path} alt="Cinque Terre" key={index} className="mb-1"/>
                    ))}
                </div>
            </Box>
        </Card>
    );
}