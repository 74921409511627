import React from "react";

const Title = (props) => {
    return(
        <h6 className="mt-3">
            <b>{props.text}</b>
        </h6>
    );
}

export default Title;