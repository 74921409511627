import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Context from "../context";
import { theme } from '../../../../theme';
import { moneyFormat } from '../../../../utils';

export default function MaterialItem({item}) {
    const context = useContext(Context);

    return (
        <TableContainer component={Paper}>
            <Table aria-label="spanning table" size='small'>
                <TableHead>
                    <TableRow>
                        <TableCell align="left" sx={{ border: 0 }}>Qty.</TableCell>
                        <TableCell align="center" sx={{ border: 0 }}>Unit ({theme.currency})</TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>Net ({theme.currency})</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                     <TableRow>
                        <TableCell align="left" sx={{ border: 0 }}>{item.request_material_qty}</TableCell>
                        <TableCell align="center" sx={{ border: 0 }}>{moneyFormat(item.request_material_unit_price_with_commission)}</TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>{moneyFormat(item.request_material_net_price)}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <Table aria-label="spanning table" size='small' sx={{ mt: 3 }}>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={2} sx={{ border: 0 }}>Tax ({theme.currency}) ({context?.state?.request_full_data?.settings?.company_vat}%)</TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>{moneyFormat(item.request_material_vat)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} sx={{ borderTop: 1 }}><b>Total ({theme.currency})</b></TableCell>
                        <TableCell align="right" sx={{ borderTop: 1 }}><b>{moneyFormat(item.request_material_price_with_vat)}</b></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}