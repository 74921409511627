import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';

import { theme } from '../../../../theme';
import { moneyFormat } from '../../../../utils';

export default function Breakdown({item}) {

    return (
        <TableContainer component={Paper}>
            <Box sx={{ p: 2 }}>
                <Typography gutterBottom variant="h6" component="div">
                    Cost Breakdown 123
                </Typography>
            </Box>
            <Divider />
            <Table aria-label="spanning table" size='small'>
                <TableBody>
                    <TableRow>
                        <TableCell colSpan={2} sx={{ border: 0 }}>Total Material Cost</TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>
                            {theme.currency} {moneyFormat(item?.total_material_price)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} sx={{ border: 0 }}>Total VAT</TableCell>
                        <TableCell align="right" sx={{ border: 0 }}>
                            {theme.currency} {moneyFormat(item?.total_vat)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2} sx={{ borderTop: 1 }}><b>Total Gross</b></TableCell>
                        <TableCell align="right" sx={{ borderTop: 1 }}>
                            <b>{theme.currency} {moneyFormat(item?.total_gross)}</b>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}