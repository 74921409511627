import React from "react";
import Spinner from 'react-bootstrap/Spinner';

const BtnSpinner = () => {
    return(
        <>
            <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
            />&nbsp;
            Loading...
        </>
    );
}

export default BtnSpinner;