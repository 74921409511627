import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { disputesRef, jobsRef } from './firebase';
import { routes } from './App/routes';
import { theme } from './theme';
import { PUT } from './API/put';
import { getUserInfo } from './storage';

const user_info = getUserInfo();

export const calHourlyRate = (skill_value, skill_company_commission, time_window) => {
    let sum = skill_value * Math.pow(0.95, time_window) * ((100 + skill_company_commission) / 100);
    return sum.toFixed(2);
};

export const diffMinutes = (time) => {
    let today = new Date();
    let start_time = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate() + " " + time;
    let diff = (new Date(start_time).getTime() - new Date().getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff)) < 15 ? 15 : Math.abs(Math.round(diff));
}

export const minutesToHHMM = (value) => {
    let minutes = parseInt(value);

    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;

    // Add leading zero if necessary
    let hoursStr = hours < 10 ? "0" + hours : hours.toString();
    let minutesStr = remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes.toString();

    return hoursStr + ":" + minutesStr;
}

export const formatHHMM = (timestamp) => {
    let newTime = new Date(parseInt(timestamp));
    // return String(newTime.getHours()).padStart(2, '0') + ":" + String(newTime.getMinutes()).padStart(2, '0');

    const options = {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'Europe/London',
        hour12: false // 24-hour format
    };
    
    // Format the time using toLocaleTimeString
    return newTime.toLocaleTimeString('en-GB', options);
}

export const timeConvert = (n, extra_time_payment) => {
    let num = n;
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    return (
        <div className="card">
            <div className="card-body ">
                <div className="row text-center">
                    <div className="col-6 border-end border-light">
                        <h6 className="text-muted mt-1 mb-2 fw-normal">Hour(s)</h6>
                        <h6 className="mb-0 fw-bold">{rhours}</h6>
                    </div>
                    <div className="col-6">
                        <h6 className="text-muted mt-1 mb-2 fw-normal">Minute(s)</h6>
                        <h6 className="mb-0 fw-bold">{rminutes}</h6>
                    </div>
                </div>

                <hr />
                <p style={{ marginBottom: '0px' }}>
                    Extra Hours Total (GBP) : {extra_time_payment?.toFixed(2)}
                </p>
            </div>
        </div>
    );
}

export const materialSubTotal = (item) => {
    let sub_total = 0;
    if (item.request_material_commission > 0) {
        sub_total = Number(item.request_material_unit_price) * Number(item.request_material_commission);
    } else {
        sub_total = Number(item.request_material_unit_price);
    }

    return sub_total?.toFixed(2);
}

export const materialTotal = (item) => {
    let total = 0;
    if (item.request_material_commission > 0) {
        total = Number(item.request_material_qty) * Number(item.request_material_unit_price) * Number(item.request_material_commission);
    } else {
        total = Number(item.request_material_qty) * Number(item.request_material_unit_price)
    }

    return total?.toFixed(2);
}

export const fullDateFormat = (timestamp) => {
    function pad2(n) { return n < 10 ? '0' + n : n }

    let date = new Date(parseInt(timestamp));
    return date.getFullYear().toString() + "-" + pad2(date.getMonth() + 1) + "-" + pad2(date.getDate()) + " " + pad2(date.getHours()) + ":" + pad2(date.getMinutes()) + ":" + pad2(date.getSeconds())
}

export const moneyFormat = (value) => {
    return (Number(value))?.toFixed(2);
}

export const totalCost = (job_details) => {
    let total = Number(job_details.job_ftm_hourly_rate) + Number(job_details.full_total_material_price) + Number(job_details.full_extra_time_payment);
    return moneyFormat(total);
}

export const truncateText = (text, maxLength) => {
    return (
        <Tooltip title={text} arrow placement="right">
            <span>
                {text !== null ? (text.length > maxLength ? text.slice(0, maxLength) + '...' : text) : "N/A"}
            </span>
        </Tooltip>
    );
};

export const getFirebaseJob = async (job_id) => {
    let job = {};

    const queryJobSnapshot = await jobsRef
        .where('job_id', '==', parseInt(job_id))
        .where('job_customer.id', '==', parseInt(user_info.customer_id))  // Add this line to check job_customer_id
        .get();

    queryJobSnapshot.forEach((doc) => {
        if (doc.exists) {
            job = {
                doc_id: doc.id,
                ...doc.data()
            }
        }
    });

    return job;
};


export const getFirebaseDisputes = async (job_id) => {
    let dispute = {};

    const queryDisputesSnapshot = await disputesRef
        .where('dispute_job.job_id', '==', parseInt(job_id))
        .where('dispute_details.customer.id', '==', parseInt(user_info.customer_id))
        .get();
    queryDisputesSnapshot.forEach((doc) => {
        if (doc.exists) {
            dispute = {
                doc_id: doc.id,
                ...doc.data()
            }
        }
    });

    return dispute;
}

export const getPaymentStatus = (payment_status) => {
    switch (payment_status) {
        case 1:
            return {
                status: "PRE AUTH",
                icon: 'priority_high',
                text_color: 'dark'
            }
        case 2:
            return {
                status: "CHARGE",
                icon: 'expand_less',
                text_color: 'success'
            }
        case 3:
            return {
                status: "RELEASE",
                icon: 'expand_more',
                text_color: 'danger'
            }
        case 4:
            return {
                status: "REFUND",
                icon: 'expand_more',
                text_color: 'danger'
            }
        default:
            return {
                status: "N/A",
                icon: 'expand_more',
                text_color: 'danger'
            }
    }
}

export const localeDateStringFormat = (timestamp) => {
    let options = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
    };

    let date = new Date(parseInt(timestamp));
    return new Intl.DateTimeFormat("en-US", options).format(date);
};

export const getCardTypeImage = (cardType) => {
    switch (cardType) {
        case "VISA":
            return "visa.png"
        case "DELTA":
            return "visa.png"
        case "UKE":
            return "visa.png"
        case "MC":
            return "mastercard.png"
        case "MCDEBIT":
            return "mastercard.png"
        case "MAESTRO":
            return "maestro.png"
        default:
            return "maestro.png"
    }
}

export const findMatchingRoute = (givenPath) => {
    const matchingRoute = routes.find(route => {
        // Create a regular expression pattern from the route path
        const regexPattern = new RegExp(`^${route.path.replace(/:\w+/g, '\\d+')}$`);
        // Test if the given path matches the pattern
        return regexPattern.test(givenPath);
    });

    return matchingRoute ? matchingRoute.page_name : "No matching route found";
}

export const textReplace = ({ text, search, replace }) => {
    let result = text.replace(search, replace);
    return result;
}

export const convertToMillions = (value) => {
    const amount = Number(value);
    let formattedAmount;

    if (amount < 1000000) {
        formattedAmount = amount.toFixed(2);
    } else if (amount < 1000000000) {
        formattedAmount = (amount / 1000000).toFixed(2) + " M";
    } else {
        formattedAmount = (amount / 1000000000).toFixed(2) + " B";
    }

    return (
        <Tooltip title={amount} arrow placement="right">
            <span>{theme.currency + " " + formattedAmount}</span>
        </Tooltip>
    );
}


export const timestampToDate = ({ timestamp, job_is_remedial_job }) => {
    // Convert timestamp to milliseconds
    let date = new Date(parseInt(timestamp));

    // Extract date components
    let year = date.getFullYear();
    let month = ('0' + (date.getMonth() + 1)).slice(-2); // Month starts from 0
    let day = ('0' + date.getDate()).slice(-2);
    let hours = ('0' + date.getHours()).slice(-2);
    let minutes = ('0' + date.getMinutes()).slice(-2);
    let seconds = ('0' + date.getSeconds()).slice(-2);

    // Construct date string in desired format
    let dateString = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    if (job_is_remedial_job == 1) {
        dateString = year + '-' + month + '-' + day;
    }

    return dateString;
}

export const formatDateInJobStatsLog = (timestamp) => {
    // Create a new Date object with the timestamp
    const date = new Date(parseInt(timestamp));

    // Get the day, month, hours, and minutes
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const year = date.getFullYear();

    // Define months array for converting month index to month name
    const months = [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN",
        "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
    ];

    // Format the date string
    const formattedDate = `${day} ${months[monthIndex]} ${year} ${hours}:${minutes < 10 ? '0' : ''}${minutes} ${hours >= 12 ? 'PM' : 'AM'}`;

    return formattedDate;
}

export const handleDownload = async (pdfLink) => {
    window.open(pdfLink, '_blank', 'noopener,noreferrer');
}

export const errorAlertIconHtml = '<img src="/assets/img/privacy_tip_60dp_0EDB6F_FILL1_wght400_GRAD0_opsz48.png" width="120" height="120">';

export const isShowChatNotifications = async (job_id, message_type) => {
    PUT("admin/show/notification", {
        job_id: job_id,
        message_type: message_type
    });
}

export const loadScript = (url, onLoad, onError) => {
    const existingScript = document.getElementById("google-maps-script");
    if (existingScript) {
        if (onLoad) onLoad();
        return;
    }

    const script = document.createElement("script");
    script.src = url;
    script.id = "google-maps-script";
    script.async = true;
    script.onload = onLoad;
    script.onerror = onError || (() => console.error("Failed to load script."));
    document.head.appendChild(script);
};

export const createMarkerContent = (iconURL) => {
    const div = document.createElement("div");
    div.style.backgroundImage = `url(${iconURL})`;
    div.style.backgroundSize = "cover";
    div.style.width = "55px";
    div.style.height = "55px";
    return div;
};

export const formatTimestampToDate = (timestamp) => {
    // Create a new Date object with the timestamp
    const date = new Date(parseInt(timestamp));

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}