export const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    return user?.token;
};

export const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    return user?.refreshToken;
};

export const updateLocalAccessToken = (token) => {
    localStorage.setItem("userInfo", JSON.stringify(token));
};