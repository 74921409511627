import React, { useContext } from "react";

import Ftm from './ftm';
import Job from './job';
import Requests from './requests';

import Context from "../context";
import { fullDateFormat, totalCost } from "../../../../utils";
import TitleBox from "../../../../common/titleBox";
import Title from "../../../../common/title";
import Total from "../../../../common/total";

const Details = () => {
    const context = useContext(Context);

    return (
        <div>
            <Job />
            <hr />

            <TitleBox
                text={`DATE & TIME : ${fullDateFormat(context.state?.job_details?.job_remedial_job_timestream)}`}
            />
            <hr />
            <Ftm />
            <hr />

            <Title
                text="MATERIALS"
            />

            <hr />
            <Requests />

            <Total
                total={totalCost(context.state?.job_details)}
            />
        </div>
    );
}

export default Details;