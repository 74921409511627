import React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export default function RequestsDirection({item}) {
    return (
        <Card variant="outlined" >
            <Box sx={{ p: 2 }}>
                <Typography gutterBottom variant="h6" component="div">
                    Description 
                </Typography>
                <Typography color="text.secondary" variant="body2">
                    {item?.ftm_request_description}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ p: 2 }}>
                <div className="scroll-container">
                    {item?.request_images?.map((item, index) => (
                        <img src={item.ftm_request_image_path} key={index} className="mb-1"/>
                    ))}
                </div>
            </Box>
        </Card>
    );
}