import React, { useContext } from "react";
import Button from 'react-bootstrap/Button';
import Card from '@mui/material/Card';

import Context from "./context";
import DatePickerCalendar from "../../../common/datePickerCalendar";
import Range from "./components/range";
import { theme } from "../../../theme";
import BtnSpinner from "../../../common/btnSpinner";
import { sendJobForwarding } from "./service";
import ModalTemplate from "../../../common/modal/modalTemplate";

const Html = () => {
    const context = useContext(Context);

    return (
        <ModalTemplate
            show = {context.show}
            closeFun = {() => context.closeModal()}
            size = {null}
            centered = {true}
            title = {"REQUEST QUOTE"}
            body = {
                <>
                    {context.job_is_have_remedial_job && (
                        <div className="alert alert-danger alert-dismissible text-white" role="alert">
                            <span className="text-sm">This job already have remedial job.</span>
                        </div>
                    )}
                    
                    <div>
                        Notice: please be aware as this will involve a return visit to the property,
                        it will add £30 to the cost to cover the labour and travel costs associated with a return visit..
                    </div>
                    <hr />
                    <Card variant="outlined" style={{ padding: '20px' }}>
                        <DatePickerCalendar label_text="Schedule Date" />
                        <hr />
                        <Range />
                    </Card>
                </>
            }
            footer = {
                <>
                    <Button
                        variant={theme.action_btn}
                        size="sm"
                        onClick={() => sendJobForwarding(context)}
                        disabled={context.state.is_sending_request}
                    >
                        {context.state.is_sending_request ? <BtnSpinner /> : "REQUEST"}
                    </Button>
                    <Button
                        variant={theme.close_btn}
                        size="sm"
                        onClick={() => context.closeModal()}
                        disabled={context.state.is_sending_request}
                    >
                        {context.state.is_sending_request ? <BtnSpinner /> : "CLOSE"}
                    </Button>
                </>
            }
        />
    );
}

export default Html;