/* eslint-disable default-case */
export default function Reducer(prevState, action){
    switch (action.type) {
        case "SET_PAYLOAD":
            return {
                ...prevState,
                payload: action.payload
            };
        case "SET_SNACKBAR":
            return {
                ...prevState,
                snackbar: action.snackbar
            };
        case "SET_DIRECTCHAT":
            return {
                ...prevState,
                directChat: action.directChat
            };
        case "SET_DISPUTESCHAT":
            return {
                ...prevState,
                disputesChat: action.disputesChat,
            };
        case "SET_OFFICECHAT":
            return {
                ...prevState,
                officeChat: action.officeChat
            };
        case "SET_FTMREQUESTALERT":
            return {
                ...prevState,
                ftmRequestAlert: action.ftmRequestAlert
            };
        case "SET_JOB_FIREBASE_LISTENER":
            return {
                ...prevState,
                job_firebase_listener: action.job_firebase_listener
            };
        case "SET_USER_FIREBASE_LISTENER":
            return {
                ...prevState,
                user_firebase_listener: action.user_firebase_listener
            };
        case "SET_SNACKBAR_AND_FTMREQUESTALERT":
            return {
                ...prevState,
                snackbar: action.snackbar,
                ftmRequestAlert: action.ftmRequestAlert,
            };
        case "SET_USER_LOGGED_IN":
            return {
                ...prevState,
                user_logged_in: action.user_logged_in
            };
        case "SET_REMEDIALJOBALERT":
            return {
                ...prevState,
                remedialJobAlert: action.remedialJobAlert
            };
        case "SET_SNACKBAR_AND_REMEDIALJOBALERT":
            return {
                ...prevState,
                snackbar: action.snackbar,
                remedialJobAlert: action.remedialJobAlert,
            };
        case "SET_RESCHEDULEJOBALERT":
            return {
                ...prevState,
                rescheduleJobAlert: action.rescheduleJobAlert
            };
        case "SET_SNACKBAR_AND_RESCHEDULEJOBALERT":
            return {
                ...prevState,
                snackbar: action.snackbar,
                rescheduleJobAlert: action.rescheduleJobAlert,
            };
    }
}