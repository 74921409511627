export const setNewJobDetails = (details) => {
    localStorage.setItem("newJobDetails", JSON.stringify(details));
}

export const getNewJobDetails = () => {
    const details = JSON.parse(localStorage.getItem("newJobDetails"));
    return details;
}

export const removeNewJobDetails = () => {
    localStorage.removeItem("newJobDetails");
};

export const setUserInfo = (info) => {
    localStorage.setItem("userInfo", JSON.stringify({...info, is_login: true}));
}

export const getUserInfo = () => {
    const info = JSON.parse(localStorage.getItem("userInfo"));
    // if(info == null){
    //     window.location = '/';
    // }
    return info;
}

export const setCurrentJob = (Job) => {
    localStorage.setItem("currentJob", JSON.stringify(Job));
    setCurrentJobRetryCount(0);
}

export const getCurrentJob = () => {
    const info = JSON.parse(localStorage.getItem("currentJob"));
    return info;
}

export const removeCurrentJob = () => {
    localStorage.removeItem("currentJob");
}

export const setIsSendFtmJobNotification = (isSendFtmJobNotification) => {
    localStorage.setItem("isSendFtmJobNotification", JSON.stringify(isSendFtmJobNotification));
};

export const setIsShowFullSidebar = (is_show) => {
    localStorage.setItem("isShowFullSidebar", is_show);
}

export const getIsShowFullSidebar = () => {
    const info = JSON.parse(localStorage.getItem("isShowFullSidebar"));
    return info;
}

export const removeUserInfo = () => {
    localStorage.removeItem("userInfo");
    removeSettings();
    removeCurrentJob();
}

export const setCurrentJobRetryCount = (count) => {
    localStorage.setItem("currentJobRetryCount", count);
}

export const getCurrentJobRetryCount = () => {
    const info = JSON.parse(localStorage.getItem("currentJobRetryCount"));
    return info;
}

export const setSettings = (settings) => {
    localStorage.setItem("settings", JSON.stringify(settings));
}

export const getSettings = () => {
    const info = JSON.parse(localStorage.getItem("settings"));
    return info;
}

export const removeSettings = () => {
    localStorage.removeItem("settings");
}

export const setRegistrationInfo = (user) => {
    localStorage.setItem("userInfo", JSON.stringify({customer_id: user.customer_id, is_login: false}));
    localStorage.setItem("registrationInfo", JSON.stringify(user));
}

export const getRegistrationInfo = () => {
    const info = JSON.parse(localStorage.getItem("registrationInfo"));
    return info;
}

export const removeRegistrationInfo = () => {
    localStorage.removeItem("registrationInfo");
}

export const setAppOpen = () => {
    localStorage.setItem("app_open", 'true');
}

export const getAppOpen = () => {
    const tabKey = localStorage.getItem("app_open");
    return tabKey;
}

export const removeAppOpen = () => {
    localStorage.removeItem("app_open");
}

export const setLastJobCurrentState = (currentState) => {
    localStorage.setItem("lastJobCurrentState", JSON.stringify(currentState));
}

export const getLastJobCurrentState = () => {
    const lastJobCurrentState = JSON.parse(localStorage.getItem("lastJobCurrentState"));
    return lastJobCurrentState;
}

export const removeLastJobCurrentState = () => {
    localStorage.removeItem("lastJobCurrentState");
}