import React, {useContext} from "react";
import Button from 'react-bootstrap/Button';
import { BsFillCheckCircleFill, BsFillDashCircleFill } from "react-icons/bs";

import Context from "../context";
import { customerActionRemedialJob } from '../service';
import BtnSpinner from "../../../../common/btnSpinner";

const ActionButtons = () => {
    const context = useContext(Context);

    return(
        <div className="container">
            <div className="row" style={{marginTop: '10px'}}>
                <div className="col">
                    <div className="text-center mb-3"> 
                        <div className="row text-center">
                            <div className="col-6">
                                <div className="d-grid gap-2">
                                    <Button 
                                        variant={context.theme.action_btn}
                                        size="sm"
                                        onClick={() => customerActionRemedialJob({
                                            job_id: context.state.job_id, 
                                            dispatch: context.dispatch,
                                            is_accept: 1,
                                            listener: context.listener,
                                        })}
                                        disabled={context?.state?.is_loading}
                                    >
                                        {context?.state?.is_loading ? (
                                            <BtnSpinner/>
                                        ) : 
                                            <>
                                                <BsFillCheckCircleFill/> APPROVE
                                            </>
                                        }
                                    </Button>  
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-grid gap-2">
                                    <Button 
                                        variant="danger" 
                                        size="sm"
                                        onClick={() => customerActionRemedialJob({
                                            job_id: context.state.job_id, 
                                            dispatch: context.dispatch,
                                            is_accept: -1,
                                            listener: context.listener,
                                        })}
                                        disabled={context?.state?.is_loading}
                                    >
                                        {context?.state?.is_loading ? (
                                            <BtnSpinner/>
                                        ) : 
                                            <>
                                                <BsFillDashCircleFill/> REJECT
                                            </>
                                        }
                                    </Button>    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ActionButtons;