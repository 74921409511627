export const initialState = {
    payload: null,
    snackbar: {},
    directChat: {},
    disputesChat: {},
    officeChat: {},
    job_firebase_listener: null,
    user_firebase_listener: null,
    user_logged_in: false,
    ftmRequestAlert: {},
    remedialJobAlert: {},
    rescheduleJobAlert: {}
}