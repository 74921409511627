import React, { useContext } from "react";
import Button from 'react-bootstrap/Button';
import { BsFillCheckCircleFill, BsFillDashCircleFill } from "react-icons/bs";
import Context from "../context";
import { customerActionRescheduleJob } from '../service';
import BtnSpinner from "../../../../common/btnSpinner";

const ActionButtons = () => {
    const { theme, state, dispatch, listener } = useContext(Context);
    const { reschedule_job_id, is_loading } = state;

    const handleActionClick = (is_approve) => {
        customerActionRescheduleJob({
            reschedule_job_id,
            dispatch,
            is_approve,
            listener,
        });
    };

    return (
        <div className="container">
            <div className="row" style={{ marginTop: '10px' }}>
                <div className="col">
                    <div className="text-center mb-3">
                        <div className="row text-center">
                            {['approve', 'reject'].map((action, index) => (
                                <div key={index} className="col-6">
                                    <div className="d-grid gap-2">
                                        <Button
                                            variant={action === 'approve' ? theme.action_btn : "danger"}
                                            size="sm"
                                            onClick={() => handleActionClick(action === 'approve' ? 1 : -1)}
                                            disabled={is_loading}
                                        >
                                            {is_loading ? (
                                                <BtnSpinner />
                                            ) : (
                                                <>
                                                    {action === 'approve' ? <BsFillCheckCircleFill /> : <BsFillDashCircleFill />} 
                                                    {action === 'approve' ? ' APPROVE' : ' REJECT'}
                                                </>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActionButtons;