import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ onComplete, timer }) => {
    const [time, setTime] = useState(timer);
    const [countdownComplete, setCountdownComplete] = useState(false);

    useEffect(() => {
        if (timer) { // Start countdown only if timer is true
            const interval = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime === 0 && !countdownComplete) {
                        clearInterval(interval);
                        // Call the callback function when the countdown is complete
                        if (onComplete && typeof onComplete === 'function') {
                            onComplete();
                            setCountdownComplete(true); // Set the flag to true to prevent further calls
                        }
                    }
                    return prevTime > 0 ? prevTime - 1 : 0;
                });
            }, 1000);

            // Clear the interval when the component is unmounted
            return () => clearInterval(interval);
        }
    }, [timer, onComplete, countdownComplete]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    return (
        <div style={{ fontWeight: 900 }}>
            {formatTime(time)}
        </div>
    );
};

export default CountdownTimer;