import React from "react";
import Card from 'react-bootstrap/Card';

import { theme } from "../theme";

const TitleBox = (props) => {
    return(
        <Card body style={{backgroundColor: theme.box_bg_color}}>
            <h6 style={{marginBottom: '0px'}}>
                <b style={{color: theme.box_text_color}}>{props.text}</b>
            </h6>
        </Card>
    );
}

export default TitleBox;