import { theme } from '../theme';
import { errorAlertIconHtml } from '../utils';
import { showSwalAlert } from '../common/swalAlert';

export const errorAlert = (message) => {
    showSwalAlert({
        type: "error", 
        alertObj: {
            html: message === "Cannot read properties of undefined (reading 'message')" ? "<b>NOT CONNECTION</b>": `<b>${message}</b>`,
            iconHtml: errorAlertIconHtml,
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonColor: theme.main_code,
            cancelButtonText: 'OK',
            allowOutsideClick: false,
        }, 
        thenFunction: () => {}
    });

    return {
        status: "Failed"
    }
}

export const badRequestError = (error) => {
    if (error.response) { 
        // status code out of the range of 2xx
        return errorAlert(error?.response?.data?.message);
    } else if (error.request) { 
        // The request was made but no response was received
        return errorAlert(error?.request);
    } else {
        // Error on setting up the request
        return errorAlert(error?.message);
    }
}