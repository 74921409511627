import React, { useContext, useEffect, useReducer } from "react";

import ListenerContext from "../../../listener/context";
import Context from "./context";
import {theme} from '../../../theme';
import Reducer from "./reducer";
import { initialState } from "./initialState";
import ViewBtn from "./components/viewBtn";
import Details from "./components/details";
import ActionButtons from "./components/actionBut";
import CloseBtn from "./components/closeBut";
import OffcanvasTemplate from "../../../common/offcanvasTemplate";

const RemedialJobAlert = ({
    show,
    job_id,
    handleClose
}) => {
    const listener = useContext(ListenerContext);
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        dispatch({
            type: 'SET_JOB_ID',
            job_id: job_id
        });
    },[job_id]);

    return(
        <>
            <Context.Provider value={{theme, state, dispatch, listener}}>
                <OffcanvasTemplate
                    open = {show}
                    handleClose = {() => handleClose()} 
                    placement = {'end'}  
                    title = {
                        state.is_show_remedial_job_details && (state?.request_status !== "APPROVE" && state?.request_status !== "REJECT") && (
                            <h3>A new remedial job requires your attention.</h3>
                        )
                    }
                    body = {
                        state.is_show_remedial_job_details ? (
                            <div className="container">
                                <div className="row">
                                    {state?.request_status == "APPROVE" && (
                                        <div className="col-lg-12">
                                            <img src="/assets/img/approved.png" className="img-responsive center-block d-block mx-auto" alt="approvedImage" style={{width: '80px'}}/>
                                            <h3 className="text-center mt-3">You have approved the remedial job!</h3>
                                        </div>
                                    )}

                                    {state?.request_status == "REJECT" && (
                                        <div className="col-lg-12">
                                            <img src="/assets/img/reject.webp" className="img-responsive center-block d-block mx-auto" alt="approvedImage" style={{width: '80px'}}/>
                                            <h3 className="text-center mt-3">You have rejected the remedial job!</h3>
                                        </div>
                                    )}

                                    <Details/>
                                </div>
                            </div>
                        ):(
                            <div className="ftm-request-notification-body">
                                <h3>REMEDIAL VISIT</h3>
                                <img src="/assets/gif/bell.gif" className="ftm-request-notification-body-icon" alt="bellIcon"/>
                                <p className="r3 px-md-5 px-sm-1">A new remedial job requires your attention</p>
                            </div>
                        )
                    }
                    footer = {
                        <div className="offcanvas-footer">
                            {state?.is_show_remedial_job_details ? (
                                <>
                                    {state?.request_status !== "APPROVE" && state?.request_status !== "REJECT" ? (
                                        <ActionButtons/>
                                    ) : <CloseBtn handleClose={() => handleClose()} /> }
                                </>
                            ) : (
                                <ViewBtn/>
                            )}
                        </div>
                    } 
                    style={null}
                />
            </Context.Provider>
        </>
    )
}
    
export default RemedialJobAlert;