export default function Reducer(prevState, action){
    switch (action.type) {
        case "LOADING":
            return {
                ...prevState,
                is_loading: true
            };
        case "LOADED":
            return {
                ...prevState,
                is_loading: false
            };
        case "OPEN":
            return {
                ...prevState,
                show: true
            };
        case "CLOSE":
            return {
                ...prevState,
                show: false,
                is_show_time_count_down_timer: false,
            };
        case "CLOSE_TIME_COUNT_DOWN":
            return {
                ...prevState,
                show: false,
                is_show_time_count_down_timer: false,
            };
        case "SET_REQUEST_DATA":
            return {
                ...prevState,
                show: true,
                is_show_time_count_down_timer: true,
                is_show_request_details: false,
                request_data: action.request_data,
                request_status: 'PENDING'
            };
        case "SET_FTM_REQUEST":
            return {
                ...prevState,
                request_full_data: action.request_full_data,
                job_is_have_remedial_job: action.job_is_have_remedial_job,
                is_show_request_details: true,
                is_loading: false,
                request_status: 'LOADED'
            };
        case "APPROVE_REQUEST":
            return {
                ...prevState,
                request_status: "APPROVE",
                is_loading: false,
                is_show_time_count_down_timer: false,
            };
        case "REJECT_REQUEST":
            return {
                ...prevState,
                request_status: "REJECT",
                is_loading: false,
                is_show_time_count_down_timer: false,
            };
        case "IS_JOB_SHOW_FORWARDING":
            return {
                ...prevState,
                is_job_show_forwarding: action.is_job_show_forwarding
            };
        case "AUTO_REJECT_MORE_TIME_AND_MATERIALS":
            return {
                ...prevState,
                is_show_time_count_down_timer: false,
                request_full_data: action.request_full_data,
                is_show_request_details: true,
                is_loading: false,
                request_status: 'LOADED'
            };
        default:
            throw new Error();
    }
}