import { GET } from "../../../API/get";
import { PUT } from "../../../API/put";
import { theme } from '../../../theme';
import { showSwalAlert } from '../../../common/swalAlert';

export const openFtmRequestAlertNotification = async (request_data, dispatch) => {
    if (typeof request_data !== 'undefined'){
        dispatch({
            type: 'SET_REQUEST_DATA',
            request_data: request_data
        });
    }
}

export const getFtmRequest = async (option) => {
    option.dispatch({ type: "LOADING" });
    let response = await GET("more-time-and-materials/" + option.request_data.job_id + "/" + option.request_data.request_id);
    if (response?.status === "Success") {
        option.dispatch({
            type: "SET_FTM_REQUEST",
            request_full_data: response.details,
            job_is_have_remedial_job: response.details.job_details?.job_is_have_remedial_job == 1 ? true : false
        });
    }
}

export const acceptOrRejectMoreTimeAndMaterials = async (option) => {
    option.dispatch({ type: "LOADING" });
    let response = await PUT("is-accept-more-time-and-materials", {
        job_id: option.request_data.job_id,
        ftm_request_id: option.request_data.request_id,
        is_accept: option.is_approve
    });

    if (response?.status === "Success") {
        if (option.is_approve == 1) {
            option.dispatch({ type: "APPROVE_REQUEST" });
            option.listener.dispatch({
                type: 'SET_SNACKBAR',
                snackbar: {
                    is_show: true,
                    type: 'success',
                    msg: "You accept new FTM request."
                }
            });
        } else {
            option.dispatch({ type: "REJECT_REQUEST" });
            option.listener.dispatch({
                type: 'SET_SNACKBAR',
                snackbar: {
                    is_show: true,
                    type: 'error',
                    msg: "You reject new FTM request."
                }
            });
        }
    }
}

export const autoRejectMoreTimeAndMaterials = async (option) => {
    option.dispatch({ type: "LOADING" });
    let response = await GET("more-time-and-materials/" + option.request_data.job_id + "/" + option.request_data.request_id);
    if (response?.status === "Success" && response?.details?.ftm_request_is_accept === 0) {
        option.dispatch({
            type: "AUTO_REJECT_MORE_TIME_AND_MATERIALS",
            request_full_data: response.details
        });

        let rejectResponse = await PUT("is-accept-more-time-and-materials", {
            job_id: option.request_data.job_id,
            ftm_request_id: option.request_data.request_id,
            is_accept: -1
        });

        if (rejectResponse?.status === "Success") {
            option.dispatch({ type: "REJECT_REQUEST" });
            option.listener.dispatch({
                type: 'SET_SNACKBAR',
                snackbar: {
                    is_show: true,
                    type: 'error',
                    msg: "Auto reject new FTM request."
                }
            });

            showSwalAlert({
                type: "error", 
                alertObj: {
                    title: "Timeout",
                    text: "Your 10-minute session has expired, and the request to initiate a Tradesperson has been automatically rejected.",
                    confirmButtonColor: theme.close_btn_code,
                    confirmButtonText: "CLOSE",
                }, 
                thenFunction: () => {}
            });
        }
    }
}