import Swal from 'sweetalert2';
import { theme } from '../../theme';

const icons = {
    success: `<svg xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 -960 960 960" width="100px" fill="${theme.main_code}"><path d="m421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Z"/></svg>`,
    error: `<svg xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 -960 960 960" width="100px" fill="${theme.main_code}"><path d="M480-334q14.45 0 24.23-9.77Q514-353.55 514-368q0-14.45-9.77-24.23Q494.45-402 480-402q-14.45 0-24.23 9.77Q446-382.45 446-368q0 14.45 9.77 24.23Q465.55-334 480-334Zm-30-134h60v-207h-60v207Zm30 387q-140-35-230-162.5T160-523v-238l320-120 320 120v238q0 152-90 279.5T480-81Z"/></svg>`,
    warning: `<svg xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 -960 960 960" width="100px" fill="${theme.main_code}"><path d="m40-120 440-760 440 760H40Zm444.18-117q12.82 0 21.32-8.68 8.5-8.67 8.5-21.5 0-12.82-8.68-21.32-8.67-8.5-21.5-8.5-12.82 0-21.32 8.68-8.5 8.67-8.5 21.5 0 12.82 8.68 21.32 8.67 8.5 21.5 8.5ZM454-348h60v-224h-60v224Z"/></svg>`,
    info: `<svg xmlns="http://www.w3.org/2000/svg" height="100px" viewBox="0 -960 960 960" width="100px" fill="${theme.main_code}"><path d="M453-280h60v-240h-60v240Zm26.98-314q14.02 0 23.52-9.2T513-626q0-14.45-9.48-24.22-9.48-9.78-23.5-9.78t-23.52 9.78Q447-640.45 447-626q0 13.6 9.48 22.8 9.48 9.2 23.5 9.2Zm.29 514q-82.74 0-155.5-31.5Q252-143 197.5-197.5t-86-127.34Q80-397.68 80-480.5t31.5-155.66Q143-709 197.5-763t127.34-85.5Q397.68-880 480.5-880t155.66 31.5Q709-817 763-763t85.5 127Q880-563 880-480.27q0 82.74-31.5 155.5Q817-252 763-197.68q-54 54.31-127 86Q563-80 480.27-80Z"/></svg>`
};

export const showSwalAlert = ({ type, alertObj, thenFunction }) => {
    const iconHtml = icons[type] || null;

    Swal.fire({
        ...alertObj,
        iconHtml,
        customClass: {
            icon: 'error-alert-custom-icon'
        }
    }).then(async (result) => thenFunction(result));
};