import React from "react";

const ImageGallery = (props) => {
    return(
        <div className="scroll-container">
            {props.images?.map((image, imageIndex) => (
                <a href={image.job_image_path} target="_blank" rel="noreferrer" key={imageIndex} >
                    <img src={image.ftm_request_image_path} alt="Cinque Terre" className="mb-1" 
                        style={{
                            minWidth: '80px',
                            maxWidth: '80px',
                            minHeight: '80px',
                            maxHeight: '80px'
                        }}
                    />
                </a>
            ))}
        </div>
    );
}

export default ImageGallery