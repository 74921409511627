import React from "react";
import Card from 'react-bootstrap/Card';
import { theme } from "../theme";

const Total = (props) => {
    return(
        <Card 
            body 
            className="mt-3"
            style={{
                backgroundColor: theme.box_bg_color,
                color: theme.box_text_color
            }}
        >
            <p 
                style={{
                    fontSize: "20px", 
                    fontWeight: '900', 
                    marginBottom: '0px'
                }}
            >
                TOTAL COST ({theme.currency}) : {props.total}
            </p>
        </Card>
    );
}

export default Total