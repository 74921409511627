import React, { useContext } from "react";

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Context from "../context";
import RequestsDirection from "./requestsDirection";
import MaterialItem from "./materialItem";
import Breakdown from "./breakdown";

const ExtensionApproved = () => {
    const context = useContext(Context);

    return (
        <React.Fragment>
            <div className="col-lg-12">
                <div className="card h-100">
                    {context?.state?.job_details?.ftm_requests?.map((item, index1) => (
                        <React.Fragment key={index1}>
                            <RequestsDirection item={item} />

                            <div className="card-body p-3 pb-0 mt-3">
                                <ul className="list-group">
                                    {item?.materials?.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <Card variant="outlined" key={index}>
                                                <Box sx={{ p: 2 }}>
                                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                        <Typography gutterBottom variant="h6" component="div">
                                                            {item.request_material_item}
                                                        </Typography>
                                                    </Stack>
                                                    <Typography color="text.secondary" variant="body2">
                                                        {item.request_material_description}
                                                    </Typography>
                                                </Box>
                                                <Divider />
                                                <Box sx={{ p: 2 }}>
                                                    <MaterialItem item={item} />
                                                </Box>
                                            </Card>
                                            <hr />
                                        </React.Fragment>
                                    ))}
                                </ul>
                            </div>

                            <Card variant="outlined">
                                <Box sx={{ p: 2 }}>
                                    <Breakdown item={item}/>
                                </Box>
                            </Card>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </React.Fragment>
    )
}

export default ExtensionApproved;