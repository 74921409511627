import React, { useReducer } from "react";
import { initialState } from "./initialState";
import Reducer from "./reducer";
import Context from "./context";
import Html from "./html";

const JobForwardingModal = ({
    show, request_data,
    job_is_have_remedial_job, 
    closeFtmRequestAlert, 
    closeModal
}) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    return(
        <Context.Provider value={{show, request_data, job_is_have_remedial_job, closeFtmRequestAlert, closeModal, state, dispatch}}>
            <Html/>
        </Context.Provider>
    );
}

export default JobForwardingModal;