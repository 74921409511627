import React, {useEffect, useReducer} from "react";
import Html from "./html";
import { loadMessages } from "./service";
import Reducer from "./reducer";
import Context from "./context";
import { initialState } from "./initialState";
import FormSubmit from "./components/formSubmit";
import OffcanvasTemplate from "../../../common/offcanvasTemplate";
import CardWithHeaderTemplate from "../../../common/cardWithHeaderTemplate";

const Chat = ({
    show, 
    dispute, 
    handleClose,
    is_inbox,
    header, 
    emptyChat,
    is_have_selected_dispute
}) => {
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        let unsubscribe;
        const fetchData = async () => {
            if (dispute) {
                unsubscribe = await loadMessages(dispute, dispatch);
            }
        };
        
        fetchData();

        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [dispute]);

    if(is_inbox){
        return (
            <Context.Provider value={{dispute, state, dispatch}}>
                <CardWithHeaderTemplate
                    header={header} 
                    body={is_have_selected_dispute ? <Html/> : emptyChat} 
                    footer= {
                        is_have_selected_dispute && (
                            <div className="card-footer d-block">
                                <FormSubmit/>
                            </div>
                        )
                    }
                    extraCardClassName = {`blur shadow-blur max-height-vh-70 h-fix-100  min-height-vh-70`}
                    extraBodyClassName = {`overflow-auto overflow-x-hidden`}
                />
            </Context.Provider>
        );
    }else{
        return(
            <Context.Provider value={{dispute, state, dispatch}}>
                <OffcanvasTemplate
                    open = {show}
                    handleClose = {() => handleClose()} 
                    placement = {'end'}  
                    title = {`DISPUTE : ${dispute?.dispute_details?.type}`}
                    body = {<Html/>} 
                    footer = { <FormSubmit/>} 
                    style={{
                        top: '10%',
                        bottom : '10%', 
                        borderRadius: "5px",
                        right: '2%'
                    }}
                />
            </Context.Provider>
        )
    }
} 

export default Chat;