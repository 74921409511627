import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import Context from "../context";

export default function Ftm() {
    const context = useContext(Context);

    return (
        <Card sx={{ display: 'flex' }}>
            <CardMedia
                component="img"
                sx={{ width: 120 }}
                image={context?.state?.job_details?.job_ftm?.ftm_profile_image_path || "/assets/img/lock_user.png"}
                alt="Live from space album cover"
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                        {context?.state?.job_details?.job_ftm?.ftm_forename} {context?.state?.job_details?.job_ftm?.ftm_surname}
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        {context?.state?.job_details?.job_ftm?.ftm_vehicle_number}
                    </Typography>
                </CardContent>
            </Box>
        </Card>
    );
}