import { useLocation } from 'react-router-dom';

const useCurrentLocation = () => {
    const location = useLocation();
    const { hash, pathname, search } = location;

    return {
        hash,
        pathname,
        search
    };
};

export default useCurrentLocation;
