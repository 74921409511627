import React, { useContext } from "react";

import Context from "../context";
import { fullDateFormat } from "../../../../utils";
import Title from "../../../../common/title";

const Details = () => {
    const context = useContext(Context);

    return (
        <>
            {context.state?.request_status === "APPROVE" && (
                <div className="col-lg-12">
                    <img src="/assets/img/approved.png" className="img-responsive center-block d-block mx-auto" alt="approvedImage" style={{width: '80px'}}/>
                    <h3 className="text-center mt-3">Your job has been rescheduled!</h3>
                </div>
            )}

            {context.state?.request_status === "REJECT" && (
                <div className="col-lg-12">
                    <img src="/assets/img/reject.webp" className="img-responsive center-block d-block mx-auto" alt="approvedImage" style={{width: '80px'}}/>
                    <h3 className="text-center mt-3">Your job has not been rescheduled!</h3>
                </div>
            )}

            <div>
                <p>Schedule Details</p>
                <Title
                    text="Original ETA"
                />
                <h6>
                    <b>{fullDateFormat(context.state?.reschedule_details?.eta_time?.start)} to {fullDateFormat(context.state?.reschedule_details?.eta_time?.end)}</b>
                </h6>

                <Title
                    text="Re-schedule ETA"
                />
                <h6>
                    <b>{fullDateFormat(context.state?.reschedule_details?.new_eta_time?.start)} to {fullDateFormat(context.state?.reschedule_details?.new_eta_time?.end)}</b>
                </h6>

                <hr/>
                <p>Re-schedule reason</p>
                <h6>
                    <b>{context.state?.reschedule_details?.reschedule_description}</b>
                </h6>
            </div>
        </>
    );
}

export default Details;