import { useNavigate } from 'react-router-dom';

const useCustomNavigate = () => {
    const navigate = useNavigate();

    const navigateToHome = () => {
        navigate('/');
    };

    const navigateBack = () => {
        navigate(-1);
    };

    const navigateToCustom = (path) => {
        navigate(path);
    }

    return {
        navigateToHome,
        navigateBack,
        navigateToCustom
    };
};

export default useCustomNavigate;
