import React, { useEffect, useContext } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { FaMapMarkerAlt } from "react-icons/fa";

import Context from "../context";

import { setupRange, getTimeString, handleRangeChange } from "../service";

const Range = () => {
    const context = useContext(Context);

    useEffect(() => {
        setupRange(context.state, context.dispatch);
    }, []);

    return (
        <>
            <label>Schedule Time</label>
            <Box display="flex" flexDirection="column" m={1.5} style={{paddingRight: '20px', paddingLeft: '20px'}}>
                <Slider
                    style={{
                        // width: 300, 
                        color: '#212529',
                        marginTop: '30px'
                    }}
                    value={context.state.range_value}
                    onChange={(event, newValue, activeThumb) => handleRangeChange(event, newValue, activeThumb, context)}
                    // valueLabelDisplay="auto"
                    valueLabelDisplay="on"
                    getAriaValueText={(value) => getTimeString(context.state.today, value)}
                    valueLabelFormat={(value) => getTimeString(context.state.today, value)}
                    disableSwap
                    min={1}
                    max={60}
                />
                <Container>
                    <Row style={{ fontWeight: "400" }}>
                        <Col style={{ fontSize: '12px', marginLeft: '-20px' }}>
                            <FaMapMarkerAlt /> ARRIVE AFTER
                        </Col>
                        <Col style={{ fontSize: '12px', marginRight: '-210px' }}>
                            ARRIVE BEFORE <FaMapMarkerAlt />
                        </Col>
                    </Row>
                </Container>
            </Box>
        </>
    );
}

export default Range;