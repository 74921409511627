import React, { useContext, useEffect, useReducer } from "react";

import ListenerContext from "../../../listener/context";
import Context from "./context";
import {theme} from '../../../theme';
import Reducer from "./reducer";
import { initialState } from "./initialState";

import ViewBtn from "./components/viewBtn";
import Details from "./components/details";
import ActionButtons from "./components/actionBut";
import CloseBtn from "./components/closeBut";
import OffcanvasTemplate from "../../../common/offcanvasTemplate";

const RescheduleJobAlert = ({
    show,
    reschedule_job_id,
    handleClose
}) => {
    const listener = useContext(ListenerContext);
    const [state, dispatch] = useReducer(Reducer,initialState);

    useEffect(() => {
        dispatch({
            type: 'SET_RESCHEDULE_JOB_ID',
            reschedule_job_id: reschedule_job_id
        });
    },[reschedule_job_id]);

    return(
        <>
            <Context.Provider value={{theme, state, dispatch, listener}}>
                <OffcanvasTemplate
                    open = {show}
                    handleClose = {() => handleClose()} 
                    placement = {'end'}  
                    title = {
                        state.is_show_reschedule_job_details && (state?.request_status !== "APPROVE" && state?.request_status !== "REJECT") && (
                            <h3>Tradesperson requesting a re-schedule.</h3>
                        )
                    }
                    body = {
                        state.is_show_reschedule_job_details ? (
                            <div className="container">
                                <div className="row">
                                    <Details/>
                                </div>
                            </div>
                        ):(
                            <div className="ftm-request-notification-body">
                                <h3>REQUEST</h3>
                                <img src="/assets/gif/bell.gif" className="ftm-request-notification-body-icon" alt="bellIcon"/>
                                <p className="r3 px-md-5 px-sm-1">Please reschedule the job; unforeseen circumstances require a time adjustment.</p>
                            </div>
                        )
                    }
                    footer = {
                        <div className="offcanvas-footer">
                            {state?.is_show_reschedule_job_details ? (
                                <>
                                    {state?.request_status !== "APPROVE" && state?.request_status !== "REJECT" ? (
                                        <ActionButtons/>
                                    ) : <CloseBtn handleClose={() => handleClose()} /> }
                                </>
                            ) : (
                                <ViewBtn/>
                            )}
                        </div>
                    } 
                    style={null}
                />
            </Context.Provider>
        </>
    )
}
    
export default RescheduleJobAlert;