import React from "react";
import Modal from 'react-bootstrap/Modal';

const ModalTemplate = ({
    show,
    closeFun,
    size,
    centered,
    title,
    body,
    footer,
    is_custom
}) => {
    return (
        <Modal 
            show={show} 
            onHide={closeFun}
            aria-labelledby="contained-modal-title-vcenter"
            centered = {centered}
            size={size}
        >
            {title != null && (
                <Modal.Header closeButton = {closeFun != null}>
                    {is_custom ? title : <Modal.Title>{title}</Modal.Title>}
                </Modal.Header>
            )} 

            <Modal.Body>
                {body}
            </Modal.Body>

            {footer != null && (
                <Modal.Footer>
                    {footer}
                </Modal.Footer>  
            )} 
        </Modal>
    );
}

export default ModalTemplate;