import React, { useContext } from 'react';
import Badge from 'react-bootstrap/Badge';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import Context from "../context";
import ImageGallery from "./imageGallery";

export default function AlertDirection() {
    const context = useContext(Context);

    return (
        <div>
            <Box sx={{ pb: 2 }}>
                <Badge bg="success">REASON DESCRIPTION</Badge>
                <Typography color="text.secondary" variant="body2" sx={{mt: 1}}>
                    {context?.state?.request_full_data?.ftm_request_description}
                </Typography>
            </Box>
            <Divider />
            <Box sx={{ pb: 2 }}>
                <Badge bg="success">REASON IMAGES</Badge>
                <ImageGallery
                    images = {context?.state?.request_full_data?.request_images}
                />
            </Box>
        </div>
    );
}