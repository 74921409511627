import React, { useContext } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';

import Context from "../context";
import { theme } from '../../../../theme';
import { moneyFormat } from '../../../../utils';

export default function AlertItem({item}) {
    const context = useContext(Context);

    return (
        <TableContainer component={Paper} variant="outlined">
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>Qty</TableCell>
                        <TableCell align="right">Unit Price ({theme.currency})</TableCell>
                        <TableCell align="right">Net Price ({theme.currency})</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{item.request_material_qty}</TableCell>
                        <TableCell align="right">{moneyFormat(item.request_material_unit_price_with_commission)}</TableCell>
                        <TableCell align="right">{moneyFormat(item.request_material_net_price)}</TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell colSpan={2}>VAT ({theme.currency}) ({context?.state?.request_full_data?.settings?.company_vat} %)</TableCell>
                        <TableCell align="right">{moneyFormat(item.request_material_vat)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}><strong>Total ({theme.currency}):</strong></TableCell>
                        <TableCell align="right"><strong>{moneyFormat(item.request_material_price_with_vat)}</strong></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}