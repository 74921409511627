import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import ChatImageUpload from "./chatImageUpload";
import Context from "../context";
import { sendTextMessages } from "../service";
import { theme } from "../../../../theme";

/**
 * Function to render the chat form submit component
 */
const FormSubmit = () => {
    // Get hook to manage form validation
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    // Get context value
    const context = useContext(Context);

    /**
     * Function to handle form submission
     * @param {Object} data - Form data
     */
    const onSubmit = (data) => {
        sendTextMessages(data, context.dispute, reset);
    };

    return (
        <form className="align-items-center p-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group input-group-outline d-flex">
                <input 
                    type="text" 
                    name="text" 
                    className="form-control form-control-lg"
                    placeholder="Type your message"
                    {...register("text", { required: true })}
                />
                <ChatImageUpload/>
                <button className={`btn bg-gradient-${theme.main} mb-0`}>
                    <i className="material-icons">send</i>
                </button>
            </div>
        </form>
    );
}

export default FormSubmit;