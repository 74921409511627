import axios from "axios";
import { getLocalAccessToken, getLocalRefreshToken, updateLocalAccessToken } from "./token.service";

const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = getLocalAccessToken();
        if (token) {
            config.headers["Authorization"] = token; // for Node.js Express back-end 
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config
        // const history = useHistory();

        if (err.response.data.message === "Refresh token is not in database!") {
            window.location = '/lockscreen';
            // history.push('/lockscreen');
        }

        // if (originalConfig.url !== "customer-login" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            try {
                const rs = await instance.post("refresh-token", {
                    refreshToken: getLocalRefreshToken(),
                });

                let user_info = {
                    customer_id: rs.data.customer_id,
                    customer_name: rs.data.customer_name,
                    customer_contact_number: rs.data.customer_contact_number,
                    customer_firebase_document_id: rs.data.customer_firebase_document_id,
                    token: rs.data.token,
                    refreshToken: rs.data.refreshToken,
                }

                // const { accessToken } = rs.data;
                updateLocalAccessToken(user_info);

                return instance(originalConfig);
            } catch (_error) {
                return Promise.reject(_error);
            }
        }
        // }

        return Promise.reject(err);
    }
);

export default instance;
