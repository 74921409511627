import React, { lazy } from 'react';

const SignIn = lazy(() => import('../auth/signIn'));
const SignUp = lazy(() => import('../auth/signUp'));
const Lockscreen = lazy(() => import('../auth/lockscreen'));
const ForgotPassword = lazy(() => import('../auth/forgotPassword'));
const EmailVerification = lazy(() => import('../auth/emailVerification'));
const CreateJobs = lazy(() => import('../jobs/createJobs'));
const CurrentJob = lazy(() => import('../jobs/currentJob'));
const AllJobs = lazy(() => import('../jobs/all'));
const JobView = lazy(() => import('../jobs/view'));
const DisputesList = lazy(() => import('../jobs/disputes/list'));
const SearchResults = lazy(() => import('../jobs/searchResults'));
const Dashboard = lazy(() => import('../dashboard'));
const Wallet = lazy(() => import('../customer/wallet'));
const Notifications = lazy(() => import('../notifications'));
const _404Error = lazy(() => import('../error/404'));
const Profile = lazy(() => import('../customer/profile'));
const PaymentsSummary = lazy(() => import('../payments/summary'));
const FutureJobsCalendar = lazy(() => import('../jobs/futureJobs/calendar'));
const FutureJobsList = lazy(() => import('../jobs/futureJobs/list'));
const OfficeMessages = lazy(() => import('../messages/office'));
const DisputesMessages = lazy(() => import('../messages/disputes'));
const JobsMessages = lazy(() => import('../messages/jobs'));

export const routes = [
    { path: "/", element: <SignIn /> , page_name: "Sign In"},
    { path: "/sign/in", element: <SignIn /> , page_name: "Sign In"},
    { path: "/sign/up/:type", element: <SignUp /> , page_name: "Sign Up"},
    { path: "/lockscreen", element: <Lockscreen /> , page_name: "Lockscreen"},
    { path: "/forgot/password/:customer_id", element: <ForgotPassword />, page_name: "Forgot Password"},
    { path: "/email/verification/:customer_id", element: <EmailVerification /> , page_name: "Email Verification"},
    { path: "/create/jobs", element: <CreateJobs /> , page_name: "Create Jobs"},
    { path: "/current/job/:job_id", element: <CurrentJob /> , page_name: "Current Job"},
    { path: "/jobs/all", element: <AllJobs /> , page_name: "All Jobs"},
    { path: "/jobs/view/:job_id", element: <JobView />, page_name: "Job View"},
    { path: "/jobs/disputes/list", element: <DisputesList /> , page_name: "Disputes List"},
    { path: "/job/search/results", element: <SearchResults />, page_name: "Search Results"},
    { path: "/dashboard", element: <Dashboard /> , page_name: "Dashboard"},
    { path: "/profile", element: <Profile /> , page_name: "Profile"},
    { path: "/wallet", element: <Wallet /> , page_name: "Wallet"},
    { path: "/payments", element: <PaymentsSummary /> , page_name: "Payments"},
    { path: "/notifications", element: <Notifications />, page_name: "Notifications" },
    { path: "/future/jobs/calendar", element: <FutureJobsCalendar />, page_name: "Future Jobs" },
    { path: "/future/jobs/list", element: <FutureJobsList />, page_name: "Future Jobs" },
    { path: "/office/messages", element: <OfficeMessages />, page_name: "Office Messages" },
    { path: "/disputes/messages", element: <DisputesMessages />, page_name: "Disputes Messages" },
    { path: "/jobs/messages", element: <JobsMessages />, page_name: "Jobs Messages" },
    { path: "/*", element: <_404Error /> , page_name: "404 Error"},
];