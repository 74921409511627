import React, {useContext} from "react";
import Button from 'react-bootstrap/Button';
import Context from "../context";

const CloseBtn = ({handleClose}) => {
    const context = useContext(Context);

    return(
        <>
            <div className="container">
                <div className="row" style={{marginTop: '10px'}}>
                    <div className="col">
                        <div className="text-center mb-3"> 
                            <div className="d-grid gap-2">
                                <Button 
                                    variant={context.theme.close_btn}
                                    size="sm"
                                    onClick={() => handleClose()}
                                    disabled={context.state.is_loading}
                                >
                                    CLOSE
                                </Button>   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CloseBtn;