import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarAlert = (props) => {
    return(
        <>
            {props.type === "alert" ? (
                <Snackbar
                    open={props.open}
                    autoHideDuration={6000}
                    onClose={props.handleClose}
                    message={props.msg}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    // action={action}
                />
            ) : (
                <Snackbar 
                    open={props.open} 
                    autoHideDuration={6000} 
                    onClose={props.handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={props.handleClose} severity={props.type} sx={{ width: '100%' }}>
                        {props.msg}
                    </Alert>
                </Snackbar>
            )}
        </>
    );
}

export default SnackbarAlert;