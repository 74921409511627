import api from "./api";
import {errorAlert, badRequestError} from "./errorHandling";

export const PUT = async (URL, body) => {
    try{
        let response = await api.put(URL, body);
        if(response.data.status === "Success"){
            return response.data
        }else{
            return errorAlert(response?.data?.message);
        }
    }catch(error){
        return badRequestError(error);
    }
};