import { GET } from "../../../API/get";
import { PUT } from "../../../API/put";

export const customerActionRescheduleJob = async (option) => {
    option.dispatch({ type: "LOADING" });
    let response = await PUT("reschedule/job/is/approval", {
        reschedule_job_id: option.reschedule_job_id,
        is_customer_approval_reschedule: option.is_approve
    });

    if(response?.status === "Success"){
        if(option.is_approve == 1){
            option.dispatch({type: "APPROVE_REQUEST"});
            option.listener.dispatch({
                type: 'SET_SNACKBAR',
                snackbar: {
                    is_show: true,
                    type: 'success',
                    msg: "Re-schedule request accepted."
                }
            });
        }else{
            option.dispatch({type: "REJECT_REQUEST"});
            option.listener.dispatch({
                type: 'SET_SNACKBAR',
                snackbar: {
                    is_show: true,
                    type: 'error',
                    msg: "Re-schedule request rejected."
                }
            });
        }
    }
}

export const rescheduleJob = async (option) => {
    option.dispatch({ type: "LOADING" });
    let response = await GET("reschedule/job/"+option.reschedule_job_id);
    if(response?.status === "Success"){
        option.dispatch({
            type: "SET_RESCHEDULE_JOB_DETAILS", 
            reschedule_details: response.details
        });
    }else{
        option.dispatch({ type: "LOADED" });
    }
}