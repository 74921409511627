export default function Reducer(prevState, action){
    switch (action.type) {
        case "LOADING":
            return {
                ...prevState,
                is_loading: true
            };
        case "LOADED":
            return {
                ...prevState,
                is_loading: false
            };
        case "OPEN":
            return {
                ...prevState,
                show: true
            };
        case "CLOSE":
            return {
                ...prevState,
                show: false
            };
        case "SET_RESCHEDULE_JOB_ID":
            return {
                ...prevState,
                reschedule_job_id: action.reschedule_job_id,
                show: true,
                request_status: 'PENDING'
            };
        case "SET_RESCHEDULE_JOB_DETAILS":
            return {
                ...prevState,
                reschedule_details: action.reschedule_details,
                is_loading: false,
                is_show_reschedule_job_details: true,
                request_status: 'PENDING'
            };
        case "APPROVE_REQUEST":
            return {
                ...prevState,
                request_status: "APPROVE",
                is_loading: false
            };
        case "REJECT_REQUEST":
            return {
                ...prevState,
                request_status: "REJECT",
                is_loading: false
            };
        default:
            throw new Error();
    }
}