export default function Reducer(prevState, action) {
    switch (action.type) {
        case 'IS_LOADING':
            return {
                ...prevState,
                is_loading: true,
                messages_list: []
            };
        case 'IS_LOADING_BTN':
            return {
                ...prevState,
                is_loading_btn: action.is_loading_btn,
            };
        case 'IS_LOADED':
            return {
                ...prevState,
                is_loading: false,
                messages_list: action.messages_list
            };  
        case "SET_IMAGES":
            return {
                ...prevState,
                images: action.images
            }; 
        default:
            throw new Error();
    }
}