import { GET } from "../../../API/get";
import { PUT } from "../../../API/put";

export const remedialJob = async (option) => {
    option.dispatch({ type: "LOADING" });
    let response = await GET("remedial/job/"+option.job_id);
    if(response?.status === "Success"){
        option.dispatch({
            type: "SET_JOB_DETAILS", 
            job_details: response.details
        });
    }else{
        option.dispatch({ type: "LOADED" });
    }
}

export const customerActionRemedialJob = async (option) => {
    option.dispatch({ type: "LOADING" });
    let response = await PUT("customer/is-accept/remedial/job", {
        job_id: option.job_id,
        is_accept: option.is_accept
    });

    if(response?.status === "Success"){
        if(option.is_accept == 1){
            option.dispatch({type: "APPROVE_REQUEST"});
            option.listener.dispatch({
                type: 'SET_SNACKBAR',
                snackbar: {
                    is_show: true,
                    type: 'success',
                    msg: "You accept new FTM request."
                }
            });
        }else{
            option.dispatch({type: "REJECT_REQUEST"});
            option.listener.dispatch({
                type: 'SET_SNACKBAR',
                snackbar: {
                    is_show: true,
                    type: 'error',
                    msg: "You reject new FTM request."
                }
            });
        }
    }
}