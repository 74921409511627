import React, {useContext} from "react";
import Button from 'react-bootstrap/Button';
import BtnSpinner from "../../../../common/btnSpinner";
import { rescheduleJob } from "../service";
import Context from "../context";

const ViewBtn = () => {
    const context = useContext(Context);

    return(
        <div className="container">
            <div className="row" style={{marginTop: '10px'}}>
                <div className="col">
                    <div className="text-center mb-3"> 
                        <div className="d-grid gap-2">
                            <Button 
                                variant={context.theme.close_btn}
                                size="sm"
                                onClick={() => rescheduleJob({
                                    reschedule_job_id: context.state.reschedule_job_id, 
                                    dispatch: context.dispatch
                                })}
                                disabled={context.state.is_loading}
                            >
                                {context.state.is_loading ? (
                                    <>
                                        <BtnSpinner/>
                                    </>
                                ) : "VIEW"}
                            </Button>   
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewBtn;